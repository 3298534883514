import React from 'react';
import {Card, CardContent, Grid} from '@mui/material';
import permissions from '../../components/RoleBasedAccessControl/permissions';
import AccessControl from '../../components/RoleBasedAccessControl';
import Unauthorized from '../../components/RoleBasedAccessControl/Unauthorized';
import {Theme} from "@mui/material/styles";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            padding: theme.spacing(4),
            maxWidth: '100ch',
            margin: '0 auto'
        }
    })
);

type Props = {
    children?: React.ReactNode
}

export const Docs = (props: Props) => {

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AccessControl allowedPermissions={[permissions.administration.view]} noAccess={<Unauthorized />}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                {props.children}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </AccessControl>
        </div>
    );
}