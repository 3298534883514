import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Button, CardContent, Grid} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {CustomCardHeader} from '../../../Common/CustomCardHeader';
import {StyledMainContentCard} from '../../../Common/StyledComponents';
import routes from '../../../../routes';
import {CustomMaterialTable} from '../../../Common/CustomMaterialTable';
import {LookerGroupItem} from '../../types';
import permissions from '../../../RoleBasedAccessControl/permissions';
import Unauthorized from '../../../RoleBasedAccessControl/Unauthorized';
import AccessControl from '../../../RoleBasedAccessControl';
import {CrispGroup} from "../../../../bravo-api-service/generated/API.service";
import {useCrispGroups} from "../../../../api/useCrispGroups";

export const ListGroups = (): JSX.Element => {
    const theme = useTheme();
    const history = useHistory();

    const [crispGroupsLoading, crispGroups] = useCrispGroups();

    return (
        <Grid container>
            <AccessControl allowedPermissions={[permissions.administration.list]} noAccess={<Unauthorized />}>
                <Grid item xs={12}>
                <StyledMainContentCard theme={theme} elevation={0}>
                    <CustomCardHeader
                        action={
                            <Link to={`${routes.admin.path}${routes.groups.path}/new`}><Button variant="contained"
                                                                                               color="secondary">Add
                                Group</Button></Link>
                        }
                        title={`${routes.groups.pathTitle}`}
                        subheader='The following groups have been configured in Crisp. You can add additional groups or edit groups from this screen.'
                    />
                    <CardContent>
                        <CustomMaterialTable
                            isLoading={crispGroupsLoading}
                            data={crispGroups}
                            columns={[
                                {title: 'Crisp Group Name', field: 'name'},
                                {
                                    title: 'Looker Group Link', field: 'lookerGroups',
                                    render: (rowData: { lookerGroups: { items: LookerGroupItem[] } }) => rowData.lookerGroups.items.filter(group => group.lookerGroup).map(group => group.lookerGroup?.name).join(", "),
                                    customFilterAndSearch: (term: string, rowData: { lookerGroups: { items: LookerGroupItem[] } }) =>
                                        rowData.lookerGroups.items.map(group => group.lookerGroup.name).join(", ").toLowerCase().includes(term.toLocaleLowerCase()),
                                    customSort: (a: CrispGroup, b: CrispGroup) => a.lookerGroups?.items.filter(group => group?.lookerGroup).map(group => group?.lookerGroup?.name).join(", ").localeCompare(b.lookerGroups?.items.filter(group => group?.lookerGroup).map(group => group?.lookerGroup?.name).join(", ") || "")
                                },
                                {
                                    title: 'Users', field: 'users',
                                    render: (rowData: { users: { items: object[] } }) => rowData.users.items.length,
                                    customFilterAndSearch: (term: string, rowData: { users: { items: object[] } }) =>
                                        rowData.users.items.length.toString().includes(term),
                                    customSort: (a: CrispGroup, b: CrispGroup) => (a?.users?.items?.length || 0) - (b?.users?.items?.length || 0)
                                },
                            ]}
                            onRowClick={(event, rowData) => {
                                let id = rowData?.name;
                                history.push(`${routes.admin.path}${routes.groups.path}/${id}`);
                            }}
                        />
                    </CardContent>
                </StyledMainContentCard>
            </Grid>
            </AccessControl>
        </Grid>
    );
}
