import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {KeyboardBackspace as KeyboardBackspaceIcon} from '@mui/icons-material';
import {makeStyles} from '@mui/styles';
import {useTheme} from '@mui/material/styles';
import {
    Button,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    TextField,
    Theme,
    Typography
} from '@mui/material';
import {API, graphqlOperation} from 'aws-amplify';
import {StyledMainContentCard} from '../../../Common/StyledComponents';
import {CustomCardHeader} from '../../../Common/CustomCardHeader';
import {EditLookerGroups} from './LookerGroups';
import {useAutocompleteStyles, useButtonProgressStyles, useFormStyles} from '../../../Common/MaterialUIStyles';
import {CustomMaterialTable} from '../../../Common/CustomMaterialTable';
import {Mutations, Queries, Types} from '../../../../bravo-api-service';
import {LookerGroup} from '../../types';
import {toast} from 'react-toastify';
import {Autocomplete} from '@mui/material';
import permissions from '../../../RoleBasedAccessControl/permissions';
import Unauthorized from '../../../RoleBasedAccessControl/Unauthorized';
import AccessControl from '../../../RoleBasedAccessControl';
import {listAllFromDynamo} from "../../../../utils";

interface RouteParams {
    groupId: string
}

export const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            padding: `${theme.spacing(1)}px 0px`,
        },
        addUserButton: {
            margin: `2px ${theme.spacing(1)}px`,
        }
    })
);

export const EditGroup = (): JSX.Element => {
    const theme = useTheme();
    const history = useHistory();
    const classes = useFormStyles();
    const editGroupClasses = useStyles();
    const autoCompleteStyles = useAutocompleteStyles();
    const buttonLoadingStyle = useButtonProgressStyles();
    let {groupId} = useParams<RouteParams>();

    const [users, setUsers] = useState<any>([]);
    const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);
    const [isAddingUser, setIsAddingUser] = useState<boolean>(false);
    const [user, setUser] = useState<any>(null);
    const [crispGroup, setCrispGroup] = useState<any>(null);
    const [isLoadingCrispGroup, setIsLoadingCrispGroup] = useState<boolean>(true);
    const [lookerGroups, setLookerGroups] = useState<any>([]);
    const [crispGroupUsers, setCrispGroupUsers] = useState<any>([]);
    const [outerCrispGroupUsers, setOuterCrispGroupUsers] = useState<any>([]);
    const [selectedLookerGroup, setSelectedLookerGroup] = useState<any>([]);
    const [userUpdateDate, setUserUpdateDate] = useState<any>(null);

    const fetchCrispGroup = async (groupId: string) => {
        try {
            const response = await API.graphql(graphqlOperation(Queries.getCrispGroup, {name: groupId})) as {
                data: Types.GetCrispGroupQuery;
            };
            setCrispGroup(response.data.getCrispGroup);
            setSelectedLookerGroup(response.data.getCrispGroup?.lookerGroups?.items?.map((group) => group?.lookerGroup));
            const userItems = await listAllFromDynamo(graphqlOperation(Queries.listGroupUsers, { filter: {groupId: {eq: groupId}}}), 'listGroupUsers');
            setCrispGroupUsers(userItems.map((user:{user:any}) => user?.user));
            setOuterCrispGroupUsers(userItems);
        } catch(error: any) {
            console.error('Error while fetching Crisp Group: ', error);
        }
        setIsLoadingCrispGroup(false);
    };

    useEffect(() => {
        fetchCrispGroup(groupId).then();
    }, [groupId]);

    useEffect(() => {
        const fetchLookerGroups = async () => {
            const result = await API.graphql(graphqlOperation(Queries.listLookerGroups)) as {
                data: Types.ListLookerGroupsQuery;
            };
            setLookerGroups(result.data.listLookerGroups);
        };
        fetchLookerGroups().then();
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                let itemsLocal = await listAllFromDynamo(graphqlOperation(Queries.listUsers), 'listUsers');
                setUsers(itemsLocal);
            } catch(error: any) {
                console.error('Error loading users: ', error);
            }
            setIsLoadingUsers(false);
        };

        fetchUsers().then();
    }, [userUpdateDate]);

    const save = async () => {
        try {
            // Delete all looker groups associated with the crisp group.
            if (crispGroup?.lookerGroups?.items) {
                await Promise.all(crispGroup?.lookerGroups?.items?.map(async (group: LookerGroup) => {
                    await API.graphql(graphqlOperation(Mutations.deleteCrispLookerGroup, {
                        input: {
                            lookerGroupId: group.lookerGroupId,
                            crispGroupId: groupId
                        }
                    }));
                }));
            }
            // Add selected looker groups to the crisp group.
            if (selectedLookerGroup) {
                await Promise.all(selectedLookerGroup?.map(async (group: LookerGroup) => {
                    try {
                        await API.graphql(graphqlOperation(Mutations.createCrispLookerGroup, {
                            input: {
                                lookerGroupId: group.lookerGroupId,
                                crispGroupId: groupId
                            }
                        }));
                    } catch (e) {
                        console.error("Error while saving Crisp Group to Looker Group Link for group", group)
                        throw e;
                    }
                }));
            }
            toast.success(`Group ${crispGroup.name} updated.`);
        } catch (error: any) {
            let errorMsg = error.errors?.map((e: { errorType: string, message: string }) => `${e.errorType}: ${e.message}`).join('. ');
            toast.error(errorMsg);
            console.error('Error while updating Crisp Group: ', error);
        } finally {
            await fetchCrispGroup(groupId);
        }
    }

    const handleUserSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            if (user) {
                setIsAddingUser(true);
                if (crispGroupUsers !== undefined) {
                    for (let u of crispGroupUsers) {
                        if (u.id === user.id) {
                            toast.info(`${u.firstName} ${u.lastName} is already in the group.`);
                            setIsAddingUser(false);
                            setUser(null);
                            return;
                        }
                    }
                }
                // Add a user to the crisp group.
                await API.graphql(graphqlOperation(Mutations.createGroupUser, {input: {userId: user.id, groupId}}));
                await fetchCrispGroup(groupId);
                setUserUpdateDate(new Date());
                setIsAddingUser(false);
                setUser(null);
                toast.success(`Group ${crispGroup.name} updated.`);
            }
        } catch (error: any) {
            setIsAddingUser(false);
            let errorMsg = error.errors?.map((e: { errorType: string, message: string }) => `${e.errorType}: ${e.message}`).join('. ');
            toast.error(errorMsg);
            console.error('Error while adding user to the Crisp Group: ', error);
        }
    }

    const handleUserRemove = async (event: React.FormEvent<HTMLFormElement>, rowData: { id: string }) => {
        try {
            await API.graphql(graphqlOperation(Mutations.deleteGroupUser, {input: {id: rowData.id}}));
            await fetchCrispGroup(groupId);
            setUserUpdateDate(new Date());
            toast.success('Removed the user from the group.')
        } catch (error: any) {
            let errorMsg = error.errors?.map((e: { errorType: string, message: string }) => `${e.errorType}: ${e.message}`).join('. ');
            toast.error(errorMsg);
            console.error('Error while removing user to the Crisp Group: ', error);
        }
    }

    if (isLoadingCrispGroup) {
        return (<Grid container>
            <div style={{margin: 'auto', paddingTop: theme.spacing(2)}}><CircularProgress/></div>
        </Grid>);
    }

    return (
        <>
            <Grid container>
                <AccessControl allowedPermissions={[permissions.administration.edit]} noAccess={<Unauthorized/>}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                        <StyledMainContentCard theme={theme} elevation={0}>
                            <CustomCardHeader
                                avatar={
                                    <IconButton aria-label='Go Back' onClick={() => history.goBack()}>
                                        <KeyboardBackspaceIcon/>
                                    </IconButton>
                                }
                                titleTypographyProps={{variant: 'h5'}}
                                subheaderTypographyProps={{variant: 'body1'}}
                                title={'Edit Group'}
                                subheader='Edit any field and select update to modify the group.'
                            />
                            <CardContent className={classes.formMargin}>
                                <Grid item xs={12}>
                                    <FormControl className={`${classes.formControl} ${editGroupClasses.root}`} required>
                                        <Typography gutterBottom variant='body2'>
                                            Group Name
                                        </Typography>
                                        <Typography>
                                            {crispGroup ? crispGroup.name : ''}
                                        </Typography>
                                    </FormControl>
                                    <FormControl className={`${classes.formControl} ${editGroupClasses.root}`} required>
                                        <EditLookerGroups options={lookerGroups}
                                                          selectedLookerGroups={selectedLookerGroup}
                                                          updateValue={setSelectedLookerGroup} saveValue={save}/>
                                    </FormControl>
                                </Grid>
                            </CardContent>
                        </StyledMainContentCard>
                    </Grid>
                </AccessControl>
            </Grid>
            <AccessControl allowedPermissions={[permissions.administration.edit]}>
                <Grid container>
                    <Grid item xs={12} sm={12} lg={8} xl={6}>
                        <StyledMainContentCard theme={theme} elevation={0}>
                            <CustomCardHeader
                                className={classes.formMargin}
                                action={
                                    <form onSubmit={handleUserSubmit}>
                                        <div className={autoCompleteStyles.editRoot}>
                                            <Autocomplete
                                                style={{width: theme.spacing(32)}}
                                                id='add-user'
                                                disableClearable
                                                options={users ? users.filter((user: { crispGroups: { items: Array<{ groupId: string } | null> } }) => user.crispGroups?.items?.filter((group: { groupId: string; } | null) => group?.groupId === groupId.toString())?.length === 0) : []}
                                                value={user ? user : null}
                                                getOptionLabel={(option: { id: string, email: string, firstName: string, lastName: string }) => `${option.firstName} ${option.lastName} (${option.email})`}
                                                onChange={(event, newValue) => setUser(newValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label='Add User'
                                                        variant='outlined'
                                                        size='small'
                                                        InputProps={{...params.InputProps, type: 'search'}}
                                                    />
                                                )}
                                            />
                                            <div
                                                className={`${autoCompleteStyles.iconMarginLeft} ${buttonLoadingStyle.wrapper}`}>
                                                <Button type='submit' variant='contained' color='secondary'
                                                        className={editGroupClasses.addUserButton}>Add</Button>
                                                {isAddingUser && <CircularProgress size={24}
                                                                                   className={buttonLoadingStyle.addButtonProgress}/>}
                                            </div>
                                        </div>
                                    </form>
                                }
                                title={'Associated Users'}
                                subheader='The following users are included in this group.'
                            />
                            <CardContent className={classes.formMargin}>
                                <Grid item xs={12}>
                                    <CustomMaterialTable
                                        isLoading={isLoadingUsers}
                                        data={outerCrispGroupUsers}
                                        columns={[
                                            {
                                                title: 'Name', field: 'user.firstName',
                                                customSort: (a: { user: { firstName: string, lastName: string } }, b: { user: { firstName: string, lastName: string } }) => `${a.user?.firstName} ${a.user?.lastName}`.toLowerCase().localeCompare(`${b.user?.firstName} ${b.user?.lastName}`),
                                                render: (rowData: { user: { firstName: string, lastName: string } }) => `${rowData.user?.firstName || "DELETED"} ${rowData.user?.lastName || "USER"}`
                                            },
                                            {
                                                title: 'Email', field: 'user.email',
                                                render: (rowData: { user: { email: string } }) => `${rowData.user?.email || "A DELETED EMAIL"}`,
                                                customSort: (a: { user: { email: string } }, b: { user: { email: string } }) => (a.user?.email || "A DELETED EMAIL").toLowerCase().localeCompare(b?.user?.email || "A DELETED EMAIL")
                                            },
                                        ]}
                                        filtering={false}
                                        actions={[
                                            {
                                                icon: () => <span style={{
                                                    fontSize: '.875rem',
                                                    color: theme.palette.secondary.main,
                                                    fontWeight: 'bold'
                                                }}>Remove</span>,
                                                tooltip: `Remove User`,
                                                onClick: handleUserRemove
                                            }
                                        ]}
                                    />
                                </Grid>
                            </CardContent>
                        </StyledMainContentCard>
                    </Grid>
                </Grid>
            </AccessControl>
        </>
    );
}
