import React from 'react';
import {Grid, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
    ({
        root: {
            padding: theme.spacing(4),
            textAlign: 'center'
        },
        h1: {
            fontSize: '2rem',
            lineHeight: '1.2',
        }
    }),
);

function Unauthorized() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component='h1' gutterBottom className={classes.h1}>401</Typography>
                    <Typography component='h2'>Unauthorized.</Typography>
                </Grid>
            </Grid>
        </div>
    );
}

export default Unauthorized;
