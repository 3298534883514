export type Statuses = {
    ACTIVE: Status,
    INACTIVE: Status,
}

export interface Status {
    name: string;
    displayName: string;
}

const statuses = {
    ACTIVE: { name: 'ACTIVE', displayName: 'Active' },
    INACTIVE: { name: 'INACTIVE', displayName: 'Inactive' },
};

export default statuses;
