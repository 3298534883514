/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API.service";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getLookerFolderForDashboard = /* GraphQL */ `query GetLookerFolderForDashboard($dashboardId: String) {
  getLookerFolderForDashboard(dashboardId: $dashboardId) {
    lookerFolderId
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLookerFolderForDashboardQueryVariables,
  APITypes.GetLookerFolderForDashboardQuery
>;
export const listLookerGroups = /* GraphQL */ `query ListLookerGroups {
  listLookerGroups {
    lookerGroupId
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLookerGroupsQueryVariables,
  APITypes.ListLookerGroupsQuery
>;
export const listDashboards = /* GraphQL */ `query ListDashboards {
  listDashboards {
    id
    name
    folderId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDashboardsQueryVariables,
  APITypes.ListDashboardsQuery
>;
export const listLookerFolders = /* GraphQL */ `query ListLookerFolders {
  listLookerFolders {
    lookerFolderId
    name
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLookerFoldersQueryVariables,
  APITypes.ListLookerFoldersQuery
>;
export const getStorageLinkEmbedUrl = /* GraphQL */ `query GetStorageLinkEmbedUrl {
  getStorageLinkEmbedUrl
}
` as GeneratedQuery<
  APITypes.GetStorageLinkEmbedUrlQueryVariables,
  APITypes.GetStorageLinkEmbedUrlQuery
>;
export const getDashboardOption = /* GraphQL */ `query GetDashboardOption($dashboardId: ID!) {
  getDashboardOption(dashboardId: $dashboardId) {
    dashboardId
    googleDocId
    googleDocName
    googleDocWebViewLink
    lookerFolder {
      lookerFolderId
      name
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardOptionQueryVariables,
  APITypes.GetDashboardOptionQuery
>;
export const listDashboardOptions = /* GraphQL */ `query ListDashboardOptions(
  $dashboardId: ID
  $filter: ModelDashboardOptionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDashboardOptions(
    dashboardId: $dashboardId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      dashboardId
      googleDocId
      googleDocName
      googleDocWebViewLink
      lookerFolder {
        lookerFolderId
        name
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDashboardOptionsQueryVariables,
  APITypes.ListDashboardOptionsQuery
>;
export const getDashboardAlert = /* GraphQL */ `query GetDashboardAlert($lookerFolderId: ID!) {
  getDashboardAlert(lookerFolderId: $lookerFolderId) {
    lookerFolderId
    alert
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardAlertQueryVariables,
  APITypes.GetDashboardAlertQuery
>;
export const listDashboardAlerts = /* GraphQL */ `query ListDashboardAlerts(
  $lookerFolderId: ID
  $filter: ModelDashboardAlertFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDashboardAlerts(
    lookerFolderId: $lookerFolderId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      lookerFolderId
      alert
      active
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDashboardAlertsQueryVariables,
  APITypes.ListDashboardAlertsQuery
>;
export const getCrispLookerGroup = /* GraphQL */ `query GetCrispLookerGroup($crispGroupId: ID!, $lookerGroupId: ID!) {
  getCrispLookerGroup(
    crispGroupId: $crispGroupId
    lookerGroupId: $lookerGroupId
  ) {
    lookerGroupId
    crispGroupId
    lookerGroup {
      lookerGroupId
      name
      __typename
    }
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCrispLookerGroupQueryVariables,
  APITypes.GetCrispLookerGroupQuery
>;
export const listCrispLookerGroups = /* GraphQL */ `query ListCrispLookerGroups(
  $crispGroupId: ID
  $lookerGroupId: ModelIDKeyConditionInput
  $filter: ModelCrispLookerGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCrispLookerGroups(
    crispGroupId: $crispGroupId
    lookerGroupId: $lookerGroupId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      lookerGroupId
      crispGroupId
      lookerGroup {
        lookerGroupId
        name
        __typename
      }
      createdAt
      updatedAt
      crispGroup {
        name
        description
        createdAt
        updatedAt
        lookerGroups {
          items {
            lookerGroupId
            crispGroupId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCrispLookerGroupsQueryVariables,
  APITypes.ListCrispLookerGroupsQuery
>;
export const getCrispGroup = /* GraphQL */ `query GetCrispGroup($name: ID!) {
  getCrispGroup(name: $name) {
    name
    description
    createdAt
    updatedAt
    lookerGroups {
      items {
        lookerGroupId
        crispGroupId
        lookerGroup {
          lookerGroupId
          name
          __typename
        }
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCrispGroupQueryVariables,
  APITypes.GetCrispGroupQuery
>;
export const listCrispGroups = /* GraphQL */ `query ListCrispGroups(
  $name: ID
  $filter: ModelCrispGroupFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCrispGroups(
    name: $name
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCrispGroupsQueryVariables,
  APITypes.ListCrispGroupsQuery
>;
export const getGroupUser = /* GraphQL */ `query GetGroupUser($id: ID!) {
  getGroupUser(id: $id) {
    id
    userId
    groupId
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    user {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      storageLinkUsername
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupUserQueryVariables,
  APITypes.GetGroupUserQuery
>;
export const listGroupUsers = /* GraphQL */ `query ListGroupUsers(
  $filter: ModelGroupUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroupUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userId
      groupId
      createdAt
      updatedAt
      crispGroup {
        name
        description
        createdAt
        updatedAt
        lookerGroups {
          items {
            lookerGroupId
            crispGroupId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        users {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      user {
        id
        firstName
        lastName
        email
        company
        role
        status
        temporaryPassword
        notes
        storageLinkUsername
        createdAt
        updatedAt
        crispGroups {
          items {
            id
            userId
            groupId
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupUsersQueryVariables,
  APITypes.ListGroupUsersQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    email
    company
    role
    status
    temporaryPassword
    notes
    storageLinkUsername
    createdAt
    updatedAt
    crispGroups {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $id: ID
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUsers(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      storageLinkUsername
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
