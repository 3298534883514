import {useEffect, useState} from 'react';
import {CognitoUser} from '@aws-amplify/auth';
import {API, Auth, graphqlOperation, Hub} from 'aws-amplify';
import {HubCapsule, HubPayload} from '@aws-amplify/core';
import {fetchError, fetchUserDataInit, resetUser, setCurrentUser, setDashboard} from '../actions';
import {useDispatch} from 'react-redux';
import {CurrentUserInfo} from '../bravo-api-service/customTypes';
import {clearLookerSessionCookie} from "./clearLookerSessionCookie";
import {Queries, Types} from "../bravo-api-service";

const ATTR_LAST_DASHBOARD = "custom:recent_dashboard";
const DEFAULT_DASHBOARD_ID = "411"

const useAmplifyAuth = (): void | any => {

    const dispatch = useDispatch();
    const [triggerFetch, setTriggerFetch] = useState(false)

    useEffect(() => {
        let isMounted = true

        const fetchUserData = async () => {
            if (isMounted) {
                dispatch(fetchUserDataInit())
            }
            try {
                if (isMounted) {
                    const cogUser: CognitoUser = await Auth.currentAuthenticatedUser()
                    const user: CurrentUserInfo = await Auth.currentUserInfo()
                    const response = await API.graphql(graphqlOperation(Queries.getUser, { id: user?.attributes["sub"] })) as {
                        data: Types.GetUserQuery;
                    };
                    user.storageLinkEmbedName = response.data.getUser?.storageLinkUsername || null;

                    user.role = await cogUser.getSignInUserSession()?.getIdToken()?.payload?.crisp_role;
                    if (user.attributes[ATTR_LAST_DASHBOARD]) {
                        dispatch(setDashboard({
                            dashboardId: String(user.attributes[ATTR_LAST_DASHBOARD]) || DEFAULT_DASHBOARD_ID,
                            dashboardFolderId: null,
                            isLoading: true
                        }));
                    } else {
                        dispatch(setDashboard({
                            dashboardId: DEFAULT_DASHBOARD_ID,
                            dashboardFolderId: null,
                            isLoading: true
                        }));
                    }
                    dispatch(setCurrentUser(user))
                }
            } catch(error: any) {
                if (isMounted) {
                    dispatch(fetchError())
                }
            }
        }

        const updateViewedDashboard = (dashboardId: string) => {
            Auth.currentAuthenticatedUser().then(user => {
                const attributes: any = {};
                attributes[ATTR_LAST_DASHBOARD] = `${dashboardId}`;
                Auth.updateUserAttributes(user, attributes).then(
                    res => {
                        if (res !== 'SUCCESS') {
                            console.error(res)
                        }
                    }
                );
            });
        }

        const listenerCallback = (data: HubCapsule) => {
            const {payload} = data
            onAuthEvent(payload)
        };

        const HubListener = () => {
            Hub.listen('user', listenerCallback)
            Hub.listen('auth', listenerCallback)
        }

        const onAuthEvent = (payload: HubPayload): any => {
            switch (payload.event) {
                case 'signIn':
                    setTriggerFetch(true)
                    break
                case 'viewDashboard':
                    updateViewedDashboard(payload.data.id);
                    break
                default:
                    return
            }
        }

        HubListener()
        fetchUserData().then();

        return () => {
            Hub.remove('user', listenerCallback)
            Hub.remove('auth', listenerCallback)
            isMounted = false
        }
    }, [triggerFetch, dispatch])

    const handleSignOut = async () => {
        try {
            //First clear looker embed tokens
            await clearLookerSessionCookie();
            //Then sign out of cognito
            await Auth.signOut();
            dispatch(resetUser());
            setTriggerFetch(false);
            window.localStorage.removeItem('bravo_google_access_key');
            window.localStorage.removeItem('bravo_google_expires_at');
        } catch(error: any) {
            console.error('Error signing out user ', error)
        }
    }

    return {handleSignOut}
}

export default useAmplifyAuth;