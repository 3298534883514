import {Auth} from "aws-amplify";
import {API} from "@aws-amplify/api";

export async function clearLookerSessionCookie() {
    await Auth.currentSession()
        .then(session => {
            return {
                headers: {
                    Authorization: `Bearer ${session
                        .getIdToken()
                        .getJwtToken()}`
                },
                withCredentials: true
            };
        }).then(restInit => {
            return API.get('lookerEmbedApi', "/looker/clear-embed-session", restInit)
        })
}