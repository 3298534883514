// View default values here: https://material-ui.com/customization/default-theme/
import {colors} from '@mui/material';
import {PaletteOptions} from '@mui/material/styles/createPalette';
import {goldenYellow} from './colors';

const palette: PaletteOptions = {
  primary: {
    light: '#C3DB49',
    main: '#A3C553',
    dark: '#729422',
    contrastText: '#fff',
  },
  secondary: {
    light: '#6BAC55',
    main: '#3B7C29',
    dark: '#004F00',
    contrastText: '#fff',

  },
  error: {
    light: '#FF5F52',
    main: '#C62828',
    dark: '#8E0000',
  },
  warning: {
    light: '#FFF176',
    main: '#FFCB3E',
    dark: '#C89B00',
  },
  info: {
    light: '#B2DFDB',
    main: '#3DD0B7',
    dark: '#009E87',
  },
  success: {
    light: '#6BAC55',
    main: '#3B7C29',
    dark: '#004F00',
  },
  tertiary: {
    dark: goldenYellow['400'],
    main: goldenYellow['300'],
    light: goldenYellow['100']
  },
  background: {
    default: '#fff',
    paper: '#fbfdf9', //`${lemonGreen['400']}0a`
  },
  text: {
    primary: '#333333',
    secondary: colors.blueGrey[600],
  },
};

export default palette;
