import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useTheme} from '@mui/material/styles';

import {AppBar, Grid, Hidden, Toolbar, Typography} from '@mui/material';
import {ExitToApp as ExitToAppIcon} from '@mui/icons-material';

import {GlobalBravoState} from '../../reducers';
import {ResponsiveNav} from './ResponsiveNav';
import {StyledNavLink, StyledSignOutButton} from '../Common/StyledComponents';
import routes from '../../routes';
import permissions from '../RoleBasedAccessControl/permissions';
import AccessControl from '../RoleBasedAccessControl';

const NavbarBrandLogo = styled.div`
  width: 144px;
`;

const NavbarBrandImg = styled.img`
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  color: ${props => props.theme.palette.text.primary};
`;

const StyledAppBar = styled(AppBar)`
  background-color: ${props => props.theme.palette.background.default};
`

interface Props {
    handleSignOut: () => void;
}

export const Topbar = (props: Props): JSX.Element => {
    const theme = useTheme();
    const currentUser = useSelector((state: GlobalBravoState) => state.amplifyAuthReducer?.currentUser);

    return (
        <StyledAppBar position='relative' elevation={0} theme={theme}>
            <Toolbar>
                <Grid container style={{justifyContent: "space-between", alignItems: "center"}}>
                    <Grid item>
                        <Grid container style={{justifyContent: "flex-start", alignItems: "center"}} spacing={3}>
                            <Grid item>
                                <NavbarBrandLogo>
                                    <NavLink to={routes.landing.path}>
                                        <NavbarBrandImg
                                            alt={theme.clientBranding?.logo.alt}
                                            src={theme.clientBranding?.logo.navbarBrandImage ?? theme.clientBranding?.logo.image}
                                        />
                                    </NavLink>
                                </NavbarBrandLogo>
                            </Grid>
                            <Hidden mdDown>
                                <Grid item>
                                    <StyledNavLink theme={theme} to={routes.landing.path} exact>
                                        {routes.landing.menuTitle}
                                    </StyledNavLink>
                                </Grid>
                                {currentUser?.storageLinkEmbedName !== null && <Grid item>
                                    <StyledNavLink theme={theme} to={routes.storageLink.path} exact>
                                        {routes.storageLink.menuTitle}
                                    </StyledNavLink>
                                    </Grid>
                                }
                                <AccessControl allowedPermissions={[permissions.administration.view]}>
                                    <Grid item>
                                        <StyledNavLink theme={theme} to={routes.docs.path}>
                                            {routes.docs.menuTitle}
                                        </StyledNavLink>
                                    </Grid>
                                </AccessControl>
                                <AccessControl allowedPermissions={[permissions.administration.view]}>
                                    <Grid item>
                                        <StyledNavLink theme={theme} to={routes.admin.path}>
                                            {routes.admin.menuTitle}
                                        </StyledNavLink>
                                    </Grid>
                                </AccessControl>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Hidden mdDown>
                        <Grid item>
                            <Grid container  style={{justifyContent: "flex-start", alignItems: "center"}}  direction={"row"} spacing={3}>
                                <Grid item>
                                    <section>
                                        <StyledTypography theme={theme} variant='body2'
                                                          color='primary'>Hello, {currentUser?.username}</StyledTypography>
                                    </section>
                                </Grid>
                                <Grid item>
                                    <StyledSignOutButton theme={theme} size='small' onClick={props.handleSignOut}
                                                         endIcon={<ExitToAppIcon/>}>
                                        Sign Out
                                    </StyledSignOutButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid item>
                            <ResponsiveNav signOut={props.handleSignOut}/>
                        </Grid>
                    </Hidden>
                </Grid>
            </Toolbar>
        </StyledAppBar>
    );
}
