/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "36b8b10782554e5daffb7848287fee43",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "36b8b10782554e5daffb7848287fee43",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://l5lwtgkcebe2nlkr4gpx2322hu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "lookerEmbedApi",
            "endpoint": "https://lhi8i3sym6.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:d10c7845-3cbe-4736-93a3-e4d0311bf573",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_hm91Be6HG",
    "aws_user_pools_web_client_id": "5tkbt01pb9jv6v2o156n5di6uc",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
