import styled from 'styled-components';
import {Button, Card} from '@mui/material';
import {NavLink} from 'react-router-dom';

export const StyledMainContentCard = styled(Card)`
    background-color: ${props => props.theme.palette.background.default};
  overflow: visible
`;

export const StyledNavLink = styled(NavLink)`
    color: ${props => props.theme.palette.secondary.main};
    font-family: ${props => props.theme.typography.button.fontFamily};
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    text-transform: uppercase;
    &.active {
        color: ${props => props.theme.palette.primary.main};
        border-bottom: 3px solid ${props => props.theme.palette.tertiary.dark};
        padding-top: 12px;
        padding-bottom: 21px;
    }
    &:hover {
        color: ${props => props.theme.palette.primary.main};
        border-bottom: 3px solid ${props => props.theme.palette.tertiary.dark};
        padding-top: 12px;
        padding-bottom: 21px;
    }
`;

export const StyledSecondaryNavLink = styled(StyledNavLink)`
    color: ${props => props.theme.palette.secondary.main};
    &.active {
        color: ${props => props.theme.palette.secondary.contrastText};
        padding-bottom: 13px;
    }
    &:hover {
        color: ${props => props.theme.palette.secondary.contrastText};
        padding-bottom: 13px;
    }
`;

export const StyledGoogleDocButton = styled.div`
    color: ${props => props.theme.palette.secondary.contrastText};
    font-family: ${props => props.theme.typography.button.fontFamily};
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    text-transform: uppercase;
    cursor: pointer;
`;

export const StyledButton = styled(Button)`
    color: ${props => props.theme.palette.primary.contrastText};
`;

export const StyledSignOutButton = styled(Button)`
    color: ${props => props.theme.palette.secondary.main};
`;

export const StyledSubmitButton = styled(Button)`
    margin-top: ${props => props.theme.spacing(3)}px;
    margin-left: ${props => props.theme.spacing(1)}px;
`;

export const StyledFormButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;
