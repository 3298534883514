import React, {useState} from 'react';
import {IconButton, TextField, Typography} from '@mui/material';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import {useAutocompleteStyles} from '../../../Common/MaterialUIStyles';

interface Props {
    value: string;
    update: (event: React.ChangeEvent<HTMLInputElement>) => void;
    save: (key: string) => void;
}

export const FirstName = ({ value, update, save }: Props): JSX.Element => {

    const [isEditing, setEditing] = useState(false);
    const autoCompleteStyles = useAutocompleteStyles();

    const submit = () => {
        setEditing(false);
        save('First name');
    };

    const onBlur = () => {
        submit();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    return (
        <>
            <Typography gutterBottom variant='body2'>
                First Name
            </Typography>
            {isEditing ? (
                <TextField
                    inputProps={{ 'aria-label': 'First Name' }}
                    autoFocus
                    fullWidth
                    name='firstName'
                    onBlur={onBlur}
                    size='small'
                    variant='outlined'
                    onKeyDown={handleKeyDown}
                    value={value}
                    onChange={update}
                />
            ) : (
                <Typography onClick={() => setEditing(true)}>
                    {value}
                    <IconButton aria-label='Edit' size='small' onClick={() => setEditing(true)} className={autoCompleteStyles.iconMarginLeft}>
                        <CreateSharpIcon fontSize='small' color='primary' />
                    </IconButton>
                </Typography>
            )}
        </>
    );
}
