import {combineReducers} from 'redux';
import {CurrentUserInfo} from '../bravo-api-service/customTypes';

type Action = {
    type: string,
    user?: CurrentUserInfo,
    googleDoc?: GoogleDoc,
    lookerDashboard?: DashboardInfo,
    dashboardNavigation?: DashboardNavigation,
    lookerFolders?: LookerFolder[],
    dashboardAlerts?: DashboardAlerts | null,
    dashboardAlert?: DashboardAlert | null,
}

export interface GoogleDoc {
    id?: string | null;
    webViewLink?: string | null;
    name?: string | null;
    isLoading?: boolean;
}

export interface LookerFolder {
    __typename: "LookerFolder",
    lookerFolderId: string;
    name: string;
}

export interface BravoUser {
    currentUser: CurrentUserInfo;
    currentUserRole: string;
}

export interface DashboardInfo {
    dashboardId?: string | null;
    dashboardEmbedUrl?: string | null;
    dashboardFolderId?: string | null;
    isLoading?: boolean;
}

export interface DashboardNavigation {
    dashboardId: string | null;
    dashboardFolderId: string | null;
}

export interface DashboardNavigationState {
    dashboardNavigation: DashboardNavigation | null;
}

export interface GlobalBravoState {
    amplifyAuthReducer: BravoUser | null;
}

export interface GoogleDocState {
    googleDoc: GoogleDoc | null;
}

export interface DashboardInfoState {
    lookerDashboard: DashboardInfo | null;
}

export interface LookerFolders {
    lookerFolders: (LookerFolder | null)[] | null;
}

export interface LookerFoldersState {
    lookerFolders: LookerFolders;
}

export interface DashboardAlert {
    id?: string;
    active: boolean;
    alertText: string;
}

export interface DashboardAlerts {
    dashboardAlerts: { [id: string]: DashboardAlert }
}

export interface SystemState {
    isLoading?: boolean;
    isError?: boolean;
    currentUser?: CurrentUserInfo | null;
    lookerDashboard: DashboardInfo | null;
    dashboardNavigation: DashboardNavigation | null;
    googleDoc: GoogleDoc | null;
    lookerFolders: LookerFolder[] | null;
    dashboardAlerts: DashboardAlerts | null;
}

const initialState: SystemState = {
    isLoading: true,
    isError: false,
    currentUser: null,
    lookerDashboard: {
        dashboardId: null,
        isLoading: true,
    },
    dashboardNavigation: {
        dashboardId: null,
        dashboardFolderId: null
    },
    googleDoc: {
        id: null,
        webViewLink: null,
        name: null,
        isLoading: true,
    },
    lookerFolders: null,
    dashboardAlerts: null,
}

const amplifyAuthReducer = (state = initialState, action: Action): any => {
    switch (action.type) {
        case 'FETCH_USER_DATA_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false,
            }
        case 'SET_CURRENT_USER':
            return {
                ...state,
                isLoading: false,
                isError: false,
                currentUser: action.user,
                currentUserRole: action.user?.role
            }

        case 'FETCH_USER_DATA_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true,
            }
        case 'RESET_USER_DATA':
            return {
                ...state,
                currentUser: null,
                currentUserGroups: [],
            }
        default:
            return state
    }
}

const googleDoc = (state = initialState.googleDoc, action: Action): any => {
    switch (action.type) {
        case 'SET_GOOGLE_DOC':
            return {
                ...state,
                id: action.googleDoc?.id,
                name: action.googleDoc?.name,
                isLoading: action.googleDoc?.isLoading,
                webViewLink: action.googleDoc?.webViewLink
            }
        case 'SET_GOOGLE_DOC_LOADING':
            return {
                ...state, isLoading: action.googleDoc?.isLoading
            }
        default:
            return state
    }
}

const lookerDashboard = (state = initialState.lookerDashboard, action: Action): any => {
    switch (action.type) {
        case 'SET_DASHBOARD':
            return {
                ...state,
                dashboardId: action.lookerDashboard?.dashboardId,
                isLoading: action.lookerDashboard?.isLoading,
                dashboardFolderId: action.lookerDashboard?.dashboardFolderId
            }
        case 'SET_DASHBOARD_ID':
            return {
                ...state,
                dashboardId: action.lookerDashboard?.dashboardId
            }
        case 'SET_DASHBOARD_LOADING':
            return {
                ...state,
                isLoading: action.lookerDashboard?.isLoading
            }
        case 'SET_DASHBOARD_FOLDER_ID':
            return {
                ...state,
                dashboardFolderId: action.lookerDashboard?.dashboardFolderId
            }
        default:
            return state
    }
}

const dashboardNavigation = (state = initialState.dashboardNavigation, action: Action): any => {
    switch(action.type) {
        case 'SET_DASHBOARD_NAVIGATION':
            return {
                ...state,
                dashboardId: action.dashboardNavigation?.dashboardId,
                dashboardFolderId: action.dashboardNavigation?.dashboardFolderId
            }
        default:
            return state;
    }
}

const lookerFolders = (state = initialState.lookerFolders, action: Action): any => {
    switch (action.type) {
        case 'SET_LOOKER_FOLDERS':
            return {
                ...state,
                lookerFolders: action.lookerFolders,
            }
        default:
            return state
    }
}

const dashboardAlerts = (state = initialState.dashboardAlerts, action: Action): any => {

    switch (action.type) {
        case 'SET_DASHBOARD_ALERTS':
            return {
                ...state,
                dashboardAlerts: action.dashboardAlerts,
            }
        case 'SET_DASHBOARD_ALERT':
            if (action.dashboardAlert && action.dashboardAlert.id) {
                return { ...state, dashboardAlerts: {
                        ...state?.dashboardAlerts, [action.dashboardAlert?.id]: {
                            active: action.dashboardAlert?.active, alertText: action.dashboardAlert?.alertText
                        }
                    }};
            }
            return state;
        default:
            return state;
    }
}

const rootReducer = combineReducers({
    amplifyAuthReducer,
    googleDoc,
    lookerDashboard,
    lookerFolders,
    dashboardNavigation,
    dashboardAlerts,
});

export default rootReducer;
