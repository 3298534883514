import React from "react";
import {useTheme} from '@mui/material/styles';

function CrispLogo(): JSX.Element {
    const theme = useTheme();

    return (
        <img
            src={theme.clientBranding?.logo.image}
            alt={theme.clientBranding?.logo.alt}
            className={theme.clientBranding?.logo.className}
        />
    );
}

export default CrispLogo;