import {AnalyticsEvent} from "../declarations/analyticsEvent";
import ReactGA, {EventArgs} from 'react-ga';

const GoogleAnalyticsRecorder = (event: AnalyticsEvent): void => {
    let analyticsEvent: EventArgs = {
        category: event.category,
        action: event.action,
        ...(event.label && {label: JSON.stringify(event.label)}),
        ...(event.nonInteraction && {nonInteraction: event.nonInteraction}),
        ...(event.value && {value: event.value})

    }
    ReactGA.event(analyticsEvent);
};

export default GoogleAnalyticsRecorder;
