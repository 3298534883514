
const routes = {
    landing: {path: '/' , pageTitle: 'Dashboard', menuTitle: 'Core'},
    docs: {path: '/docs', pageTitle: 'Documents', menuTitle: 'Documentation'},
    admin: {path: '/admin', pageTitle: 'Administration', menuTitle: 'Administration'},
    users: {path: '/users', pathTitle: 'User Management', menuTitle: 'Users'},
    groups: {path: '/groups', pathTitle: 'Group Management', menuTitle: 'Groups'},
    storageLink: {path: '/slink', pathTitle: 'StorageLink', menuTitle: 'Cache'},
    dashboardAlerts: {path: '/dashboard-alerts', pathTitle: 'Dashboard Alerts', menuTitle: 'Dashboard Alerts'},
};

export default routes;
