export * from './generated/graphql/mutations';

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;