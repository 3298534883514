const permissions = {
    administration: {
        view: 'admin:view',
        list: 'admin:list',
        edit: 'admin:edit',
        create: 'admin:create',
    },
};

export default permissions;
