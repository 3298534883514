/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API.service";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    firstName
    lastName
    email
    company
    role
    status
    temporaryPassword
    notes
    storageLinkUsername
    createdAt
    updatedAt
    crispGroups {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    firstName
    lastName
    email
    company
    role
    status
    temporaryPassword
    notes
    storageLinkUsername
    createdAt
    updatedAt
    crispGroups {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    firstName
    lastName
    email
    company
    role
    status
    temporaryPassword
    notes
    storageLinkUsername
    createdAt
    updatedAt
    crispGroups {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createCrispLookerGroup = /* GraphQL */ `mutation CreateCrispLookerGroup($input: CreateCrispLookerGroupInput!) {
  createCrispLookerGroup(input: $input) {
    lookerGroupId
    crispGroupId
    lookerGroup {
      lookerGroupId
      name
      __typename
    }
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCrispLookerGroupMutationVariables,
  APITypes.CreateCrispLookerGroupMutation
>;
export const updateCrispLookerGroup = /* GraphQL */ `mutation UpdateCrispLookerGroup($input: UpdateCrispLookerGroupInput!) {
  updateCrispLookerGroup(input: $input) {
    lookerGroupId
    crispGroupId
    lookerGroup {
      lookerGroupId
      name
      __typename
    }
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCrispLookerGroupMutationVariables,
  APITypes.UpdateCrispLookerGroupMutation
>;
export const deleteCrispLookerGroup = /* GraphQL */ `mutation DeleteCrispLookerGroup($input: DeleteCrispLookerGroupInput!) {
  deleteCrispLookerGroup(input: $input) {
    lookerGroupId
    crispGroupId
    lookerGroup {
      lookerGroupId
      name
      __typename
    }
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCrispLookerGroupMutationVariables,
  APITypes.DeleteCrispLookerGroupMutation
>;
export const createCrispGroup = /* GraphQL */ `mutation CreateCrispGroup($input: CreateCrispGroupInput!) {
  createCrispGroup(input: $input) {
    name
    description
    createdAt
    updatedAt
    lookerGroups {
      items {
        lookerGroupId
        crispGroupId
        lookerGroup {
          lookerGroupId
          name
          __typename
        }
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCrispGroupMutationVariables,
  APITypes.CreateCrispGroupMutation
>;
export const updateCrispGroup = /* GraphQL */ `mutation UpdateCrispGroup($input: UpdateCrispGroupInput!) {
  updateCrispGroup(input: $input) {
    name
    description
    createdAt
    updatedAt
    lookerGroups {
      items {
        lookerGroupId
        crispGroupId
        lookerGroup {
          lookerGroupId
          name
          __typename
        }
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCrispGroupMutationVariables,
  APITypes.UpdateCrispGroupMutation
>;
export const deleteCrispGroup = /* GraphQL */ `mutation DeleteCrispGroup($input: DeleteCrispGroupInput!) {
  deleteCrispGroup(input: $input) {
    name
    description
    createdAt
    updatedAt
    lookerGroups {
      items {
        lookerGroupId
        crispGroupId
        lookerGroup {
          lookerGroupId
          name
          __typename
        }
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    users {
      items {
        id
        userId
        groupId
        createdAt
        updatedAt
        crispGroup {
          name
          description
          createdAt
          updatedAt
          lookerGroups {
            nextToken
            __typename
          }
          users {
            nextToken
            __typename
          }
          __typename
        }
        user {
          id
          firstName
          lastName
          email
          company
          role
          status
          temporaryPassword
          notes
          storageLinkUsername
          createdAt
          updatedAt
          crispGroups {
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCrispGroupMutationVariables,
  APITypes.DeleteCrispGroupMutation
>;
export const createGroupUser = /* GraphQL */ `mutation CreateGroupUser($input: CreateGroupUserInput!) {
  createGroupUser(input: $input) {
    id
    userId
    groupId
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    user {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      storageLinkUsername
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupUserMutationVariables,
  APITypes.CreateGroupUserMutation
>;
export const updateGroupUser = /* GraphQL */ `mutation UpdateGroupUser($input: UpdateGroupUserInput!) {
  updateGroupUser(input: $input) {
    id
    userId
    groupId
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    user {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      storageLinkUsername
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupUserMutationVariables,
  APITypes.UpdateGroupUserMutation
>;
export const deleteGroupUser = /* GraphQL */ `mutation DeleteGroupUser($input: DeleteGroupUserInput!) {
  deleteGroupUser(input: $input) {
    id
    userId
    groupId
    createdAt
    updatedAt
    crispGroup {
      name
      description
      createdAt
      updatedAt
      lookerGroups {
        items {
          lookerGroupId
          crispGroupId
          lookerGroup {
            lookerGroupId
            name
            __typename
          }
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      users {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    user {
      id
      firstName
      lastName
      email
      company
      role
      status
      temporaryPassword
      notes
      storageLinkUsername
      createdAt
      updatedAt
      crispGroups {
        items {
          id
          userId
          groupId
          createdAt
          updatedAt
          crispGroup {
            name
            description
            createdAt
            updatedAt
            __typename
          }
          user {
            id
            firstName
            lastName
            email
            company
            role
            status
            temporaryPassword
            notes
            storageLinkUsername
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupUserMutationVariables,
  APITypes.DeleteGroupUserMutation
>;
export const createDashboardOption = /* GraphQL */ `mutation CreateDashboardOption($input: CreateDashboardOptionInput!) {
  createDashboardOption(input: $input) {
    dashboardId
    googleDocId
    googleDocName
    googleDocWebViewLink
    lookerFolder {
      lookerFolderId
      name
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDashboardOptionMutationVariables,
  APITypes.CreateDashboardOptionMutation
>;
export const updateDashboardOption = /* GraphQL */ `mutation UpdateDashboardOption($input: UpdateDashboardOptionInput!) {
  updateDashboardOption(input: $input) {
    dashboardId
    googleDocId
    googleDocName
    googleDocWebViewLink
    lookerFolder {
      lookerFolderId
      name
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDashboardOptionMutationVariables,
  APITypes.UpdateDashboardOptionMutation
>;
export const deleteDashboardOption = /* GraphQL */ `mutation DeleteDashboardOption($input: DeleteDashboardOptionInput!) {
  deleteDashboardOption(input: $input) {
    dashboardId
    googleDocId
    googleDocName
    googleDocWebViewLink
    lookerFolder {
      lookerFolderId
      name
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDashboardOptionMutationVariables,
  APITypes.DeleteDashboardOptionMutation
>;
export const createDashboardAlert = /* GraphQL */ `mutation CreateDashboardAlert($input: CreateDashboardAlertInput!) {
  createDashboardAlert(input: $input) {
    lookerFolderId
    alert
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDashboardAlertMutationVariables,
  APITypes.CreateDashboardAlertMutation
>;
export const updateDashboardAlert = /* GraphQL */ `mutation UpdateDashboardAlert($input: UpdateDashboardAlertInput!) {
  updateDashboardAlert(input: $input) {
    lookerFolderId
    alert
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDashboardAlertMutationVariables,
  APITypes.UpdateDashboardAlertMutation
>;
export const deleteDashboardAlert = /* GraphQL */ `mutation DeleteDashboardAlert($input: DeleteDashboardAlertInput!) {
  deleteDashboardAlert(input: $input) {
    lookerFolderId
    alert
    active
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDashboardAlertMutationVariables,
  APITypes.DeleteDashboardAlertMutation
>;
