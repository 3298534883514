import React from 'react';
import {Autocomplete} from '@mui/lab';
import {TextField} from '@mui/material';
import {LookerGroup} from '../../types';

interface Props {
    options: LookerGroup[];
    updateValue: (value: string[]) => void;
}

export const LookerGroups = ({ options, updateValue }: Props): JSX.Element => {

    const handleChange = (event: React.ChangeEvent<{}>, newValue: LookerGroup[]) => {
        updateValue(newValue.map(value => value.lookerGroupId));
    };

    return (
        <Autocomplete
            multiple
            openOnFocus
            id='lookerGroupId'
            options={options ? options : []}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            size='small'
            onChange={(event, newValue) => handleChange(event, newValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant='outlined'
                    label='Looker Group Link *'
                />
            )}
        />
    );
}
