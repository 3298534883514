import {SimplePaletteColorOptions, ThemeOptions} from '@mui/material/styles';
import { createTheme } from '@mui/material/styles'
import {AmplifyTheme, ClientBranding} from './';

export default function createMyTheme(options: ThemeOptions) {
    let {clientBranding, amplify, ...otherOptions} = options;

    const branding : ClientBranding = {
        logo: {
            image: require('./assets/crisp.png'),
            alt: "Crisp Analytics",
            className: "logo",
            navbarBrandImage: require('./assets/crisp.png')
        },
        qrCodeIssuer: "Crisp",
        pageTitle: "Good Apple - Crisp Analytics",
        manifest: require('./assets/manifest.webmanifest'),
        favIcons: [
            {rel: "apple-touch-icon", sizes: "57x57", href:require('./assets/apple-icon-57x57.png')},
            {rel: "apple-touch-icon", sizes: "60x60", href:require('./assets/apple-icon-60x60.png')},
            {rel: "apple-touch-icon", sizes: "72x72", href:require('./assets/apple-icon-72x72.png')},
            {rel: "apple-touch-icon", sizes: "76x76", href:require('./assets/apple-icon-76x76.png')},
            {rel: "apple-touch-icon", sizes: "114x114", href:require('./assets/apple-icon-114x114.png')},
            {rel: "apple-touch-icon", sizes: "120x120", href:require('./assets/apple-icon-120x120.png')},
            {rel: "apple-touch-icon", sizes: "144x144", href:require('./assets/apple-icon-144x144.png')},
            {rel: "apple-touch-icon", sizes: "152x152", href:require('./assets/apple-icon-152x152.png')},
            {rel: "apple-touch-icon", sizes: "180x180", href:require('./assets/apple-icon-180x180.png')},
            {rel: "icon", type: "image/png", sizes: "36x36", href: require('./assets/android-icon-36x36.png')},
            {rel: "icon", type: "image/png", sizes: "48x48", href: require('./assets/android-icon-48x48.png')},
            {rel: "icon", type: "image/png", sizes: "72x72", href: require('./assets/android-icon-72x72.png')},
            {rel: "icon", type: "image/png", sizes: "96x96", href: require('./assets/android-icon-96x96.png')},
            {rel: "icon", type: "image/png", sizes: "144x144", href: require('./assets/android-icon-144x144.png')},
            {rel: "icon", type: "image/png", sizes: "192x192", href:require('./assets/android-icon-192x192.png')},
            {rel: "icon", type: "image/png", sizes: "32x32", href:require('./assets/favicon-32x32.png')},
            {rel: "icon", type: "image/png", sizes: "96x96", href:require('./assets/favicon-96x96.png')},
            {rel: "icon", type: "image/png", sizes: "16x16", href:require('./assets/favicon-16x16.png')},
        ],
    }

    const amplifyStyles : AmplifyTheme = {
        toast: {
            display: 'flex',
            justifyContent: 'space-between',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '50%',
            zIndex: 99,
            boxShadow: '0 0 5px 0 rgba(0,0,0,0.3)',
            padding: '16px',
            backgroundColor: otherOptions.palette?.primary?.hasOwnProperty('main') ? (otherOptions.palette?.primary as SimplePaletteColorOptions).main : '#31465F',
            fontSize: '14px',
            color: '#fff',
            boxSizing: 'border-box'
        }
    }

    // noinspection DuplicatedCode
    return createTheme({
        clientBranding: {
            ...branding,
            ...clientBranding
        },
        amplify: {
            ...amplifyStyles,
            ...amplify
        },
        ...otherOptions,
    })
}
