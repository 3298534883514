import {Components} from "@mui/material";

const components: Components = {
    MuiTextField: {
        defaultProps: {
            variant: "standard"
        }
    }
}

export default components;