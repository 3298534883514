import {ThemeOptions} from '@mui/material';
import {AmplifyOptions, AmplifyTheme, ClientBranding, ClientBrandingOptions} from './themeTypes'
import createMyTheme from './createMyTheme'
import palette from './palette';
import typography from './typography';
import components from './components';
import './logo.css';
import './common.css';

const getSubdomain = () => {
    let hostname = window.location.hostname.toLowerCase();
    const hostnameSections = hostname.split(".");
    return hostnameSections[0];
};

const {REACT_APP_THEME_OVERRIDE} = process.env;

function importThemeIfExists(themeName: String) {
    return import(`./custom/${themeName}`).catch(() => {
        console.log(`Theme folder (${themeName}) not found.`);
        return Promise.reject();
    });
}

// Use the subdomain to set the theme, or override with the REACT_APP_THEME_OVERRIDE environment variable
const overridesPromise: Promise<{ themeOptions: ThemeOptions }> = importThemeIfExists(getSubdomain()).catch(() => {
    if(REACT_APP_THEME_OVERRIDE) {
        return importThemeIfExists(REACT_APP_THEME_OVERRIDE).catch(()=>{});
    } else {
        return Promise.resolve({});
    }
})


// Overload the createMuiTheme type to support custom styling options
declare module '@mui/material/styles/createTheme' {
    interface Theme {
        clientBranding?: ClientBranding,
        amplify?: AmplifyTheme
    }

    interface ThemeOptions {
        clientBranding?: ClientBrandingOptions,
        amplify?: AmplifyOptions;
    }
}

// Overload the createPalette type to support new color options
declare module '@mui/material/styles/createPalette' {
    interface Palette {
        tertiary: Palette['primary'];
    }

    interface PaletteOptions {
        tertiary?: PaletteOptions['primary'];
    }
}

export const customThemePromise = overridesPromise.then(themeOverrides => {
    return createMyTheme({
        ...themeOverrides?.themeOptions,
        palette: { ...palette, ...themeOverrides?.themeOptions?.palette},
        typography: {...typography, ...themeOverrides?.themeOptions?.typography},
        components: {...components, ...themeOverrides?.themeOptions?.components},
        zIndex: {
            appBar: 900,
            drawer: 899
        }
    });
});

export const baseTheme = createMyTheme({
    palette,
    typography,

    zIndex: {
        appBar: 900,
        drawer: 899
    }
});

const theme = {
    baseTheme,
    customThemePromise
};

export default theme;

export * from './themeTypes'
