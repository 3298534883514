import "typeface-source-sans-pro";
import "typeface-poppins"
import {TypographyOptions} from "@mui/material/styles/createTypography";

const headingCopyFontFamily = 'Poppins, sans-serif';
const bodyCopyFontFamily = 'Source Sans Pro, serif';

const typographyOptions : TypographyOptions =  {
  fontFamily: bodyCopyFontFamily,
  h1: {
    fontFamily: headingCopyFontFamily
  },
  h2: {
    fontFamily: headingCopyFontFamily
  },
  h3: {
    fontFamily: headingCopyFontFamily
  },
  h4: {
    fontFamily: headingCopyFontFamily
  },
  h5: {
    fontFamily: headingCopyFontFamily
  },
  h6: {
    fontFamily: headingCopyFontFamily
  },
  subtitle1: {
    fontFamily: headingCopyFontFamily
  },
  subtitle2: {
    fontFamily: headingCopyFontFamily
  },
  body1: {
    fontFamily: bodyCopyFontFamily
  },
  body2: {
    fontFamily: bodyCopyFontFamily
  },
  button: {
    fontFamily: headingCopyFontFamily
  },
  caption: {
    fontFamily: headingCopyFontFamily
  },
  overline: {
    fontFamily: headingCopyFontFamily
  }
};

export default typographyOptions;
