import roles from './roles';
import permissions from './permissions';

const rules = {
    [roles.ADMINISTRATOR.name]: {
        static: [
            permissions.administration.view,
            permissions.administration.list,
            permissions.administration.edit,
            permissions.administration.create,
        ]
    },
};

export default rules;
