import {CurrentUserInfo} from '../bravo-api-service/customTypes';
import {DashboardAlert, DashboardAlerts, DashboardInfo, GoogleDoc, LookerFolder, DashboardNavigation} from '../reducers';

export const setCurrentUser = (user: CurrentUserInfo) => ({
    type: 'SET_CURRENT_USER',
    user
});

export const fetchUserDataInit = () => ({
    type: 'FETCH_USER_DATA_INIT'
});

export const fetchError = () => ({
    type: 'FETCH_USER_DATA_FAILURE'
});

export const resetUser = () => ({
    type: 'RESET_USER_DATA'
});

export const setDashboard = (lookerDashboard: DashboardInfo) => ({
    type: 'SET_DASHBOARD',
    lookerDashboard
});

export const setDashboardId = (lookerDashboard: DashboardInfo) => ({
    type: 'SET_DASHBOARD_ID',
    lookerDashboard
});

export const setDashboardFolderId = (lookerDashboard: DashboardInfo) => ({
    type: 'SET_DASHBOARD_FOLDER_ID',
    lookerDashboard
});

export const setDashboardLoading = (lookerDashboard: DashboardInfo) => ({
    type: 'SET_DASHBOARD_LOADING',
    lookerDashboard
});

export const setDashboardNavigation = (dashboardNavigation: DashboardNavigation) => ({
    type: 'SET_DASHBOARD_NAVIGATION',
    dashboardNavigation
})

export const setGoogleDoc = (googleDoc: GoogleDoc) => ({
    type: 'SET_GOOGLE_DOC',
    googleDoc
});

export const setGoogleDocLoading = (googleDoc: GoogleDoc) => ({
    type: 'SET_GOOGLE_DOC_LOADING',
    googleDoc
});

export const setLookerFolders = (lookerFolders: (LookerFolder | null)[] | null | undefined) => ({
    type: 'SET_LOOKER_FOLDERS',
    lookerFolders
});

export const setDashboardAlerts = (dashboardAlerts: DashboardAlerts | null) => ({
    type: 'SET_DASHBOARD_ALERTS',
        dashboardAlerts
});

export const setDashboardAlert = (dashboardAlert: DashboardAlert | null) => ({
    type: 'SET_DASHBOARD_ALERT',
    dashboardAlert
});
