import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useTheme} from '@mui/material/styles';
import {Button, CardContent, Grid} from '@mui/material';
import {CustomCardHeader} from '../../../Common/CustomCardHeader';
import {StyledMainContentCard} from '../../../Common/StyledComponents';
import routes from '../../../../routes';
import {CustomMaterialTable} from '../../../Common/CustomMaterialTable';
import {graphqlOperation} from 'aws-amplify';
import roles, {Roles} from '../../../RoleBasedAccessControl/roles';
import {CrispGroup} from '../../types';
import {Queries} from '../../../../bravo-api-service';
import statuses, {Statuses} from '../status';
import permissions from '../../../RoleBasedAccessControl/permissions';
import AccessControl from '../../../RoleBasedAccessControl';
import Unauthorized from '../../../RoleBasedAccessControl/Unauthorized';
import {listAllFromDynamo} from "../../../../utils";

export const ListUsers = (): JSX.Element => {
    const theme = useTheme();
    const history = useHistory();

    const [users, setUsers] = useState<any>([]);
    const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(true);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const graphqlOp = graphqlOperation(Queries.listUsers);
                const listFieldName = 'listUsers';
                let itemsLocal = await listAllFromDynamo(graphqlOp, listFieldName);

                setUsers(itemsLocal);
            } catch(error: any) {
                console.error('Error occurred while loading users: ', error);
            }
            setIsLoadingUsers(false);
        };

        fetchUsers();
    }, []);

    return (
        <Grid container>
            <AccessControl allowedPermissions={[permissions.administration.list]} noAccess={<Unauthorized />}>
                <Grid item xs={12}>
                <StyledMainContentCard theme={theme} elevation={0}>
                    <CustomCardHeader
                        action={
                            <Link to={`${routes.admin.path}${routes.users.path}/new`}><Button variant="contained" color="secondary">Add User</Button></Link>
                        }
                        title={`${routes.users.pathTitle}`}
                        subheader='The following users have been configured for Crisp. You can add new users or edit existing users from this screen.'
                    />
                    <CardContent>
                        <CustomMaterialTable
                            isLoading={isLoadingUsers}
                            data={users}
                            columns={[
                                {title: 'Name', field: 'firstName',
                                    render: (rowData: { firstName: string, lastName: string }) => `${rowData.firstName} ${rowData.lastName}`,
                                    customFilterAndSearch: (term: string, rowData: { firstName: string, lastName: string }) =>
                                        `${rowData.firstName} ${rowData.lastName}`.toLowerCase().includes(term.toLocaleLowerCase())
                                },
                                {title: 'Email', field: 'email'},
                                {title: 'Company', field: 'company'},
                                {title: 'StorageLink Embed Username', field: 'storageLinkUsername'},
                                {title: 'Crisp Groups', field: 'crispGroups',
                                    render: (rowData: { crispGroups: { items: CrispGroup[] } }) => rowData.crispGroups.items.map(group => group.groupId).join(", "),
                                    customFilterAndSearch: (term: string, rowData: { crispGroups: { items: CrispGroup[] } }) =>
                                        rowData.crispGroups.items.map(group => group.groupId).join(", ").toLowerCase().includes(term.toLocaleLowerCase())
                                },
                                {title: 'Role', field: 'role',
                                    render: (rowData: { role: string }) => roles[rowData.role as keyof Roles].displayName,
                                    customFilterAndSearch: (term: string, rowData: { role: string }) =>
                                        roles[rowData.role as keyof Roles].displayName.toLowerCase().includes(term.toLocaleLowerCase())
                                },
                                {title: 'Status', field: 'status',
                                    render: (rowData: { status: string }) => statuses[rowData.status as keyof Statuses].displayName },
                            ]}
                            onRowClick={(event, rowData) => {
                                let id = rowData?.id;
                                history.push(`${routes.admin.path}${routes.users.path}/${id}`);
                            }}
                        />
                    </CardContent>
                </StyledMainContentCard>
            </Grid>
            </AccessControl>
        </Grid>
    );
};
