import {makeStyles} from '@mui/styles';
import {Theme} from '@mui/material';

export const useAutocompleteStyles = makeStyles((theme: Theme) =>
    ({
        editRoot: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& > *': {
                marginRight: theme.spacing(0.5),
                marginTop: theme.spacing(0.5),
            },
        },
        iconMarginLeft: {
            marginLeft: theme.spacing(0.5)
        },
    }),
);

export const useFormStyles = makeStyles((theme: Theme) =>
    ({
        formControl: {
            minWidth: 120,
            width: '100%',
            marginBottom: "1em"
        },
        formHelperText: {
            marginLeft: '0px',
            marginRight: '0px',
        },
        formMargin: {
            marginLeft: theme.spacing(8),
        }
    }),
);

export const useButtonProgressStyles = makeStyles((theme: Theme) =>
    ({
        wrapper: {
            position: 'relative',
        },
        buttonProgress: {
            color: theme.palette.primary.light,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: -8,
        },
        addButtonProgress: {
            color: theme.palette.primary.light,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginLeft: -12,
            marginTop: -12,
        },
    }),
);

export const alertsTableStyles = makeStyles((theme: Theme) =>
    ({
        table: {
            width: '100%',
            borderSpacing: 0,
        },
        scrollableTable: {
            display: 'block',
            overflow: 'auto',
            width: '100%',

        },
        tableCell: {
            backgroundColor: theme.palette.background.default,
            textAlign: 'left',
            padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
        },
        tableCellHead: {
            backgroundColor: theme.palette.background.default,
            color: theme.palette.secondary.main,
            fontWeight: 900,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
    }),
);
