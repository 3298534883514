import React, {useState} from 'react';
import {IconButton, TextField, Typography} from '@mui/material';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import {useAutocompleteStyles} from '../../Common/MaterialUIStyles';
import {useTheme} from '@mui/material/styles';

interface Props {
    value: string;
    active: boolean;
    update: (event: React.ChangeEvent<HTMLInputElement>) => void;
    save: () => void;
}

export const AlertText = ({ value, active, update, save }: Props): JSX.Element => {
    const theme = useTheme();

    const [isEditing, setEditing] = useState(false);
    const autoCompleteStyles = useAutocompleteStyles();

    const submit = () => {
        setEditing(false);
        save();
    };

    const onBlur = () => {
        submit();
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    return isEditing ? (
                <TextField
                    inputProps={{ 'aria-label': 'Alert Text' }}
                    multiline rows={2}
                    autoFocus
                    fullWidth
                    name='alertText'
                    onBlur={onBlur}
                    size='small'
                    variant='outlined'
                    onKeyDown={handleKeyDown}
                    value={value}
                    onChange={update}
                    placeholder={'Alert text...'}
                />
            ) : (
                <Typography style={active ? {color: theme.palette.text.primary} : {color: theme.palette.action.disabled}} onClick={() => setEditing(true)}>
                    {(value === '' || value === undefined || value === null) && !active ? 'Alert text...' : value}
                    <IconButton aria-label='Edit' size='small' onClick={() => setEditing(true)} className={autoCompleteStyles.iconMarginLeft}>
                        <CreateSharpIcon fontSize='small' color='primary' />
                    </IconButton>
                </Typography>
            );
}
