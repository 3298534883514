import React, {useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {setGoogleDoc, setGoogleDocLoading} from '../../../actions';
import {StyledButton, StyledGoogleDocButton} from '../../Common/StyledComponents';
import {useTheme} from '@mui/material/styles';
import {API, graphqlOperation} from 'aws-amplify';
import {Mutations} from '../../../bravo-api-service';
import {DashboardNavigationState, GoogleDocState} from '../../../reducers';
import {Menu, MenuItem} from '@mui/material';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import useDrivePicker from 'react-google-drive-picker';

// The Browser API key obtained from the Google API Console.
const {REACT_APP_GOOGLE_API_KEY} = process.env;
// The Client ID obtained from the Google API Console.
const {REACT_APP_GOOGLE_CLIENT_ID} = process.env;

declare global {
    interface Window {
        gapi: any;
        google: any;
    }
}

const Picker = (): JSX.Element => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [oauthToken, setOauthToken] = useState<string | null>(null);
    const [openPicker] = useDrivePicker();

    const theme = useTheme();
    const dispatch = useDispatch();
    const googleDoc = useSelector((state: GoogleDocState) => state.googleDoc) || null;
    const lookerDashboard = useSelector((state: DashboardNavigationState ) => state.dashboardNavigation || null);

    useEffect(() => {
        if (window.localStorage.getItem('bravo_google_access_key')) {
            setOauthToken(window.localStorage.getItem('bravo_google_access_key'));
        }
    }, [])

    const isTokenExpired = (): boolean => {
        return window.localStorage.getItem('bravo_google_expires_at') === null
            || window.localStorage.getItem('bravo_google_expires_at') === undefined
            || window.localStorage.getItem('bravo_google_expires_at') === 'undefined'
            || parseInt(window.localStorage.getItem('bravo_google_expires_at') ?? '') <= Date.now();
    }

    const SCOPES = 'https://www.googleapis.com/auth/drive.metadata.readonly';

    const googleLogin = useGoogleLogin({
        onSuccess: async (authResponse) => {
            await setOauthToken(authResponse.access_token);
            window.localStorage.setItem('bravo_google_access_key', authResponse.access_token);
            window.localStorage.setItem('bravo_google_expires_at', String(Date.now() + authResponse.expires_in * 1000));
            callOpenPicker();
        },
        scope: SCOPES,
        onError: errorResponse => console.log(errorResponse),
    });

    const createGoogleDocToLookDashboardLink = async ({dashboardId, googleDocId, googleDocWebViewLink, googleDocName}: any) => {
        await API.graphql(graphqlOperation(Mutations.createDashboardOption, {input: {dashboardId, googleDocId, googleDocWebViewLink, googleDocName}}));
    }

    const updateGoogleDocToLookDashboardLink = async ({dashboardId, googleDocId, googleDocWebViewLink, googleDocName}: any) => {
        await API.graphql(graphqlOperation(Mutations.updateDashboardOption, {input: {dashboardId, googleDocId, googleDocWebViewLink, googleDocName}}));
    }

    const removeGoogleDoc = async ({dashboardId}: any) => {
        await API.graphql(graphqlOperation(Mutations.deleteDashboardOption, {input: {dashboardId}}));
    }

    const pickerCallback = useCallback((data: any) => {
        if (data.action === window.google.picker.Action.PICKED) {
            dispatch(setGoogleDocLoading({isLoading: true}));
            const fileId: string = data.docs[0].id;
            // Get more information about the file.
            axios.get('https://www.googleapis.com/drive/v3/files/' + fileId.toString() + '', {
                params: {
                    key: REACT_APP_GOOGLE_API_KEY,
                    fields: 'webViewLink,name'
                },
                headers: {
                    'Authorization': 'Bearer ' + oauthToken
                }
            }).then(response => {
                let sharingDoc = (response.data.webViewLink).replace(/usp=drivesdk/gi, 'usp=sharing');
                sharingDoc = sharingDoc.replace(/\/view\?/gi, '/preview?');
                if (googleDoc?.id !== null) {
                    // Update link between google doc and looker dashboard.
                    updateGoogleDocToLookDashboardLink({
                        dashboardId: lookerDashboard?.dashboardId, googleDocId: fileId,
                        googleDocWebViewLink: sharingDoc, googleDocName: response.data.name})
                        .then(() => {
                            dispatch(setGoogleDoc({id: fileId, webViewLink: sharingDoc, name: response.data.name, isLoading: false}));
                        })
                        .catch(err => {
                            console.error(err);
                        });
                } else {
                    // Save link between google doc and looker dashboard.
                    createGoogleDocToLookDashboardLink({
                        dashboardId: lookerDashboard?.dashboardId, googleDocId: fileId,
                        googleDocWebViewLink: sharingDoc, googleDocName: response.data.name})
                        .then(() => {
                            dispatch(setGoogleDoc({id: fileId, webViewLink: sharingDoc, name: response.data.name, isLoading: false}));
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
            }).catch((error: any) => {
                console.error(error);
            });
        }
    }, [dispatch, googleDoc, lookerDashboard, oauthToken]);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRemove = () => {
        dispatch(setGoogleDocLoading({isLoading: true}));
        removeGoogleDoc({dashboardId: lookerDashboard?.dashboardId})
            .then(() => {
                dispatch(setGoogleDoc({id: null, webViewLink: null, name: null, isLoading: false}));
            })
            .catch(err => {
                console.error('Error while disassociating looker dashboard and google doc.', err);
            });
        setAnchorEl(null);
    }

    const callOpenPicker = useCallback(() => {
        openPicker({
            // @ts-ignore
            clientId: REACT_APP_GOOGLE_CLIENT_ID,
            // @ts-ignore
            developerKey: REACT_APP_GOOGLE_API_KEY,
            // @ts-ignore
            token: oauthToken || window.localStorage.getItem('bravo_google_access_key'),
            showUploadView: true,
            showUploadFolders: true,
            multiselect: true,
            callbackFunction: pickerCallback
        });
    }, [oauthToken, openPicker, pickerCallback]);

    const handleOpenPicker = useCallback(() => {
        if (!oauthToken || isTokenExpired()) {
            googleLogin();
        } else {
            callOpenPicker();
        }
    }, [oauthToken, googleLogin, callOpenPicker])

    if (googleDoc?.id == null) {
        return (
            <StyledGoogleDocButton theme={theme} onClick={handleOpenPicker}>
                Add Google Doc
            </StyledGoogleDocButton>
        );
    } else {
        return (
            <>
                <StyledButton theme={theme} onClick={handleOpen} endIcon={<ArrowDropDownOutlinedIcon />}>
                    {googleDoc?.name}
                </StyledButton>
                <Menu
                    id='google-doc-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleRemove}>Remove Google Doc <DeleteForeverOutlinedIcon fontSize='small' color='error' /></MenuItem>
                    <MenuItem onClick={() => window.open(googleDoc?.webViewLink ?? "", "_blank")}>Edit Google Doc <EditOutlinedIcon fontSize='small' color='primary' /></MenuItem>
                    <MenuItem onClick={handleOpenPicker}>Change Google Doc <SwapHorizIcon fontSize='small' color='secondary' /></MenuItem>
                </Menu>
            </>
        );
    }

}

const GoogleDocPicker = (): JSX.Element => {

    // @ts-ignore
    return <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}><Picker/></GoogleOAuthProvider>;
}

export default GoogleDocPicker;
