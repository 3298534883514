import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {CardContent, CircularProgress, FormControl, Grid, IconButton, TextField} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {KeyboardBackspace as KeyboardBackspaceIcon} from '@mui/icons-material';
import {CustomCardHeader} from '../../../Common/CustomCardHeader';
import {StyledFormButtonsWrapper, StyledMainContentCard, StyledSubmitButton} from '../../../Common/StyledComponents';
import {LookerGroups} from './LookerGroups';
import {useButtonProgressStyles, useFormStyles} from '../../../Common/MaterialUIStyles';
import {API, graphqlOperation} from 'aws-amplify';
import {Mutations, Queries, Types} from '../../../../bravo-api-service';
import routes from '../../../../routes';
import {toast} from 'react-toastify';
import permissions from '../../../RoleBasedAccessControl/permissions';
import Unauthorized from '../../../RoleBasedAccessControl/Unauthorized';
import AccessControl from '../../../RoleBasedAccessControl';

export const CreateGroup = (): JSX.Element => {
    const theme = useTheme();
    const history = useHistory();
    const classes = useFormStyles();
    const buttonLoadingStyle = useButtonProgressStyles();

    const [name, setName] = useState('');
    const [lookerGroups, setLookerGroups] = useState<any>([]);
    const [selectedLookerGroup, setSelectedLookerGroup] = useState<any>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setName(ev.target.value);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            setIsSubmitting(true);
            // Creates a crisp group.
            const newGroup = await API.graphql(graphqlOperation(Mutations.createCrispGroup, {input: { name }})) as {
                data: Types.CreateCrispGroupMutation
            };
            // Links the crisp group to the selected looker groups.
            await Promise.all(selectedLookerGroup.map(async (lookerGroupId: String) => {
                await API.graphql(graphqlOperation(Mutations.createCrispLookerGroup, {input: {lookerGroupId, crispGroupId: newGroup.data.createCrispGroup?.name}}));
            }));
            setIsSubmitting(false);
            toast.success(`Created ${newGroup?.data?.createCrispGroup?.name} group.`);
            history.push(`${routes.admin.path}${routes.groups.path}`);
        } catch(error: any) {
            setIsSubmitting(false);
            console.error('Error creating a crisp group: ', error);
        }
    }

    const onCancel = () => {
        history.push(`${routes.admin.path}${routes.groups.path}`);
    }

    useEffect(() => {
        const fetchLookerGroups = async () => {
            const result = await API.graphql(graphqlOperation(Queries.listLookerGroups)) as {
                data: Types.ListLookerGroupsQuery;
            };
            setLookerGroups(result.data.listLookerGroups);
        };
        fetchLookerGroups();
    }, []);

    return (
        <Grid container>
            <AccessControl allowedPermissions={[permissions.administration.create]} noAccess={<Unauthorized />}>
                <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                <StyledMainContentCard theme={theme} elevation={0}>
                    <CustomCardHeader
                        avatar={
                            <IconButton aria-label='Go Back' onClick={() => history.goBack()}>
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        }
                        titleTypographyProps={{ variant: 'h5' }}
                        subheaderTypographyProps={{ variant: 'body1' }}
                        title={'Add Group'}
                        subheader='Complete the following fields and select create to add a new group to the system.'
                    />
                    <CardContent className={classes.formMargin}>
                        <form autoComplete='off' onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} required>
                                        <TextField variant='outlined' label='Group Name' size='small' name='name' value={name} onChange={handleChange} required />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl className={classes.formControl} variant='outlined'>
                                        <LookerGroups options={lookerGroups} updateValue={setSelectedLookerGroup} />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <StyledFormButtonsWrapper>
                                <StyledSubmitButton theme={theme} color='secondary' type='reset' onClick={onCancel}>Cancel</StyledSubmitButton>
                                <div className={buttonLoadingStyle.wrapper}>
                                    <StyledSubmitButton theme={theme} variant='contained' color='secondary' type='submit'
                                                        disabled={name === '' || selectedLookerGroup.length === 0}>Create</StyledSubmitButton>
                                    {isSubmitting && <CircularProgress size={24} className={buttonLoadingStyle.buttonProgress} />}
                                </div>
                            </StyledFormButtonsWrapper>
                        </form>
                    </CardContent>
                </StyledMainContentCard>
            </Grid>
            </AccessControl>
        </Grid>
    );
};
