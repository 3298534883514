export type Roles = {
    ADMINISTRATOR: Role,
    USER: Role,
}

export interface Role {
    name: string;
    displayName: string;
    level: number;
}

const roles = {
    ADMINISTRATOR: { name: 'ADMINISTRATOR', displayName: 'System Administrator', level: 10 },
    USER: { name: 'USER', displayName: 'User', level: 50 },
};

export default roles;
