import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Switch} from '@mui/material';
import {AlertText} from './AlertText';
import {alertsTableStyles} from '../../Common/MaterialUIStyles';
import { API, graphqlOperation } from 'aws-amplify';
import {Mutations, Types} from '../../../bravo-api-service';
import {setDashboardAlert} from '../../../actions';

interface Props {
    id: string;
    folderName: string;
    active: boolean;
    alertText: string;
    state: 'create' | 'update';
    disableSwitch: boolean;
}

export const AlertRow = ({ id, folderName, disableSwitch, ...props }: Props): JSX.Element => {
    const classes = alertsTableStyles();
    const dispatch = useDispatch();

    const [state, setState] = useState<'create' | 'update'>(props.state);
    const [active, setActive] = useState<boolean>(props.active);
    const [alertText, setAlertText] = useState<string>(props.alertText);
    const [previousAlertTextValue, setPreviousAlertTextValue] = useState<string>(props.alertText);

    React.useEffect(() => {
        setActive(props.active);
    }, [props.active]);

    React.useEffect(() => {
        setState(props.state);
    }, [props.state]);

    React.useEffect(() => {
        setAlertText(props.alertText);
        setPreviousAlertTextValue(props.alertText);
    }, [props.alertText])

    const createDashboardAlert = async ({id, alertText, checked}: any) => {
        return await API.graphql(graphqlOperation(Mutations.createDashboardAlert,
            {input: { lookerFolderId: id, alert: alertText, active: checked}}
        )) as { data: Types.CreateDashboardAlertMutation; };
    }

    const updateDashboardAlert = async ({id, alertText, checked}: any) => {
        return await API.graphql(graphqlOperation(Mutations.updateDashboardAlert,
            {input: { lookerFolderId: id, alert: alertText, active: checked}}
        )) as { data: Types.UpdateDashboardAlertMutation; };
    }

    const upsertDashboardAlert = async ({id, alertText, checked}: any) => {
        if (state === 'create') {
            setState('update');
            await createDashboardAlert({id, alertText, checked});
        } else {
            await updateDashboardAlert({id, alertText, checked});
        }
    }

    const toggleSwitch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setActive(checked);
        dispatch(setDashboardAlert({id ,active: checked, alertText}));
        await upsertDashboardAlert({id, alertText, checked});
    };

    const updateAlertText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlertText(event.target.value);
    }

    const saveAlertText = async () => {
        if (alertText !== previousAlertTextValue) {
            await upsertDashboardAlert({id, alertText, checked: active})
            setPreviousAlertTextValue(alertText);
            dispatch(setDashboardAlert({id ,active, alertText}));
        }
    }

    return (
        <tr>
            <td className={classes.tableCell} style={{ width: '15em' }}>
                {folderName}
            </td>
            <td className={classes.tableCell} style={{ width: '100px' }}>
                <Switch checked={active} disabled={disableSwitch} onChange={toggleSwitch}/>
            </td>
            <td className={classes.tableCell}>
                <AlertText
                    active={active}
                    value={alertText}
                    update={updateAlertText}
                    save={saveAlertText}
                />
            </td>
        </tr>
    );
}
