import {API} from "aws-amplify";

function isJSON(str: string) {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        console.log(error, str);
        return false;
    }
}

export { isJSON };

export async function listAllFromDynamo(graphqlOp: {
    query: any;
    variables: { };
    authToken: string
}, listFieldName: string) {
    let response = await API.graphql(graphqlOp) as { data: any };
    let itemsLocal;
    if (response?.data[listFieldName]?.items) {
        itemsLocal = response?.data[listFieldName]?.items;
        while (response.data[listFieldName]?.nextToken) {
            let variables = graphqlOp.variables || {};
            graphqlOp.variables = {...variables, nextToken: response.data[listFieldName].nextToken};
            response = await API.graphql(graphqlOp) as {
                data: any
            };
            if (response?.data[listFieldName]?.items) {
                itemsLocal = itemsLocal.concat(response.data[listFieldName].items);
            }
        }
    }
    return itemsLocal;
}