import React from 'react';
import {Typography} from '@mui/material';

interface Props {
    value: string;
    update: (event: React.ChangeEvent<HTMLInputElement>) => void;
    save: (key: string) => void;
}

export const Email = ({ value}: Props): JSX.Element => {

    return (
        <>
            <Typography gutterBottom variant='body2'>
                Email Address
            </Typography>
            <Typography>
                {value}
            </Typography>
        </>
    );
}
