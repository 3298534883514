import React, {LinkHTMLAttributes, useEffect, useState} from 'react';
import '@aws-amplify/ui-react/styles.css';
import {baseTheme, customThemePromise} from './theme';
import {darken, lighten, StyledEngineProvider, Theme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import routes from './routes';
import LandingPage from './components/LandingPage';
import useAmplifyAuth from './api/useAmplifyAuth';
import analyticsHubListener from "./analytics/analyticsHubListener";

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Topbar} from './components/Topbar';
import Docs from './docs';
import StorageLinkEmbed from './components/StorageLinkEmbed';
import {Authenticator, ThemeProvider as AmplifyThemeProvider} from "@aws-amplify/ui-react"

import {useSelector} from 'react-redux';
import {GlobalBravoState} from './reducers';
import {Grid, Typography} from '@mui/material';
import CrispLogo from './components/CrispLogo';
import {CreateUser} from './components/Admin/Users/Create';
import {ListUsers} from './components/Admin/Users/List';
import {ListGroups} from './components/Admin/Groups/List';
import {SecondaryTopbar} from './components/Topbar/Secondary';
import {CreateGroup} from './components/Admin/Groups/Create';
import {EditUser} from './components/Admin/Users/Edit';
import {EditGroup} from './components/Admin/Groups/Edit';
import permissions from './components/RoleBasedAccessControl/permissions';
import AccessControl from './components/RoleBasedAccessControl';
import ReactGA from 'react-ga';
import GoogleDocPicker from './components/Admin/GoogleDocPicker';
import {DashboardAlerts} from './components/Admin/DashboardAlerts';

const getAmplifyTheme = (theme: Theme) => {
    return {
        name: "crispTheme",
        tokens: {
            fonts: {
                default: {
                    variable: {value: theme?.typography?.h1?.fontFamily || 'Poppins, sans-serif'},
                    static: {value: theme?.typography?.h1?.fontFamily ||  'Poppins, sans-serif'}
                }
            },
            colors: {
                background:{
                    primary: {value: theme?.palette?.background?.default}
                },
                brand: {
                    primary: {
                        10: {value: lighten(theme?.palette?.primary?.main, 0.7)},
                        20: {value: lighten(theme?.palette?.primary?.main, 0.6)},
                        40: {value: lighten(theme?.palette?.primary?.main, 0.4)},
                        60: {value: lighten(theme?.palette?.primary?.main, 0.2)},
                        80: {value: theme?.palette?.primary?.main},
                        90: {value: darken(theme?.palette?.primary?.main, 0.1)},
                        100: {value: darken(theme?.palette?.primary?.main, 0.2)},
                    },
                    secondary: {
                        10: {value: lighten(theme?.palette?.secondary?.main, 0.7)},
                        20: {value: lighten(theme?.palette?.secondary?.main, 0.6)},
                        40: {value: lighten(theme?.palette?.secondary?.main, 0.4)},
                        60: {value: lighten(theme?.palette?.secondary?.main, 0.2)},
                        80: {value: theme?.palette?.secondary?.main},
                        90: {value: darken(theme?.palette?.secondary?.main, 0.1)},
                        100: {value: darken(theme?.palette?.secondary?.main, 0.2)},
                    },
                },
            },
        }
    }
}

const getFormFields =  (theme: Theme) => ({
    setupTOTP: {
        QR: {
            totpIssuer: theme.clientBranding?.qrCodeIssuer
        },
    },
    confirmSignIn: {
        confirmation_code: {
            label: '',
            placeholder: 'Code',
            isRequired: true,
        },
    },
})

const getComponents = (theme:Theme) => ({
    ConfirmSignIn: {
        Header() {

            return (
                <Typography variant={"h5"} component="span">Enter Your Authentication Code</Typography>
            );
        },
        Footer() {
            return <Typography variant={"body1"}>Find the authentication code in the app used during the account setup (Google Authenticator)</Typography>;
        },
    }
})

ReactGA.initialize(String(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER), {debug: process.env.NODE_ENV=== "development"});

function App() {
    interface AuthProtectedProps {
        theme: Theme,
        user: { username: string } | undefined,
        handleSignOut: void | any
    }

    const AuthProtectedContent = ({theme, user, handleSignOut}: AuthProtectedProps): JSX.Element => {
        if (user) {
            //User is logged in, allow them in.
            return (
                <Router>
                    <ToastContainer/>
                    <Topbar handleSignOut={handleSignOut}/>
                    <main>
                        <Switch>
                            <Route path={routes.landing.path} exact>
                                <AccessControl allowedPermissions={[permissions.administration.view]}>
                                    <SecondaryTopbar menu={[
                                        {
                                            component: <GoogleDocPicker />,
                                        },
                                    ]} />
                                </AccessControl>
                                <LandingPage/>
                            </Route>
                            <Route path={`${routes.storageLink.path}`}>
                                <StorageLinkEmbed/>
                            </Route>
                            <Route path={`${routes.docs.path}`}>
                                <Docs/>
                            </Route>
                            <Route path={`${routes.admin.path}`}>
                                <AccessControl allowedPermissions={[permissions.administration.view]}>
                                    <SecondaryTopbar menu={[
                                        {
                                            name: routes.users.menuTitle,
                                            route: `${routes.admin.path}${routes.users.path}`
                                        },
                                        {
                                            name: routes.groups.menuTitle,
                                            route: `${routes.admin.path}${routes.groups.path}`
                                        },
                                        {
                                            name: routes.dashboardAlerts.menuTitle,
                                            route: `${routes.admin.path}${routes.dashboardAlerts.path}`
                                        },
                                    ]}/>
                                </AccessControl>
                                <Switch>
                                    <Route path={`${routes.admin.path}${routes.users.path}`} exact>
                                        <ListUsers/>
                                    </Route>
                                    <Route path={`${routes.admin.path}${routes.users.path}/new`} exact>
                                        <CreateUser/>
                                    </Route>
                                    <Route path={`${routes.admin.path}${routes.users.path}/:userId`} exact>
                                        <EditUser/>
                                    </Route>
                                    <Route path={`${routes.admin.path}${routes.groups.path}`} exact>
                                        <ListGroups/>
                                    </Route>
                                    <Route path={`${routes.admin.path}${routes.groups.path}/new`} exact>
                                        <CreateGroup/>
                                    </Route>
                                    <Route path={`${routes.admin.path}${routes.groups.path}/:groupId`} exact>
                                        <EditGroup/>
                                    </Route>
                                    <Route path={`${routes.admin.path}${routes.dashboardAlerts.path}`} exact>
                                        <DashboardAlerts />
                                    </Route>
                                    <Redirect to={`${routes.admin.path}${routes.users.path}`}/>
                                </Switch>
                            </Route>
                            <Redirect to={routes.landing.path}/>
                        </Switch>
                    </main>
                </Router>
            );
        }

        //User not logged-in, use authenticator
        return (
            <>
                <Grid container style={{justifyContent: "flex-start"}} spacing={4}>
                    <Grid item xs={12} md={5} lg={5} xl={3}>
                        <CrispLogo/>
                    </Grid>
                </Grid>
                <Grid style={{justifyContent: "center"}} container>
                    <Grid item>
                        <AmplifyThemeProvider theme={getAmplifyTheme(theme)}>
                            <Authenticator hideSignUp={true} formFields={getFormFields(theme)} components={getComponents(theme)}>
                            </Authenticator>
                        </AmplifyThemeProvider>
                    </Grid>
                </Grid>
            </>
        )
    }

    const [theme, setTheme] = useState<Theme>(baseTheme);
    const [links, setLinks] = useState<LinkHTMLAttributes<HTMLLinkElement>[]>([]);
    const [themeLoaded, setThemeLoaded] = useState<Boolean>(false);
    const {handleSignOut} = useAmplifyAuth();
    analyticsHubListener();
    const user = useSelector((state: GlobalBravoState) => state.amplifyAuthReducer?.currentUser);

    useEffect(() => {
        customThemePromise.then((theme: Theme) => {
            setTheme(theme);
            const links: LinkHTMLAttributes<HTMLLinkElement>[] = [
            ]
            links.push(...theme?.clientBranding?.favIcons || []);
            setLinks(links);
            setThemeLoaded(true);
        });
    }, []);

    if (themeLoaded) {
        return (
            <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <HelmetProvider>
                    <Helmet title={theme.clientBranding?.pageTitle}
                            link={links}>
                    </Helmet>
                    <CssBaseline/>
                    <AuthProtectedContent theme={theme} user={user} handleSignOut={handleSignOut}/>
                </HelmetProvider>
            </ThemeProvider>
            </StyledEngineProvider>
        );
    }

    return <></>;
}

export default App;
