import React, {useState} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import {IconButton, TextField, Typography, CircularProgress} from '@mui/material';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import {useAutocompleteStyles, useFormStyles} from '../../../Common/MaterialUIStyles';
import {LookerGroup} from '../../types';

interface Props {
    selectedLookerGroups: LookerGroup[];
    updateValue: (value: LookerGroup[]) => void;
    saveValue: () => void;
    options: LookerGroup[];
}

export const EditLookerGroups = ({ options, selectedLookerGroups, updateValue, saveValue }: Props): JSX.Element => {

    const classes = useAutocompleteStyles();
    const formClasses = useFormStyles();
    const [isEditing, setEditing] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

    const submit = async () => {
        setIsLoadingSubmit(true);
        await saveValue();
        setIsLoadingSubmit(false);
        setEditing(!isEditing);
    }

    const onBlur = () => {
        submit();
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    const handleChange = (event: React.ChangeEvent<{}>, newValue: LookerGroup[]) => {
        updateValue(newValue);
    };

    return (
        <>
            <Typography  gutterBottom variant='body2'>
                Looker Group Link
            </Typography>

            {isEditing ? (
                <div className={`${formClasses.formControl} ${classes.editRoot}`}>
                    <Autocomplete
                        style={{ width: '90%' }}
                        multiple
                        openOnFocus
                        id='looker-groups'
                        options={options ? options : []}
                        getOptionLabel={(option) => option.name}
                        filterSelectedOptions
                        isOptionEqualToValue={(option, value) =>  option.lookerGroupId === value.lookerGroupId}
                        size='small'
                        value={selectedLookerGroups ? selectedLookerGroups: []}
                        onChange={(event, newValue) => handleChange(event, newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                autoFocus
                                onBlur={onBlur}
                                onKeyDown={onKeyDown}
                                variant='outlined'
                            />
                        )}
                    />
                    {isLoadingSubmit &&
                        <div className={classes.iconMarginLeft} >
                            <CircularProgress color='secondary' size={24} />
                        </div>
                    }
                </div>
            ) : (
                <div className={classes.editRoot}>
                    <Typography>
                        {selectedLookerGroups !== undefined ? selectedLookerGroups.map(group => group.name).join(', ') : ''}
                    </Typography>
                    <IconButton className={classes.iconMarginLeft} aria-label='Edit' size='small' onClick={() => setEditing(true)}>
                        <CreateSharpIcon fontSize='small' color='primary' />
                    </IconButton>
                </div>
            )}
        </>
    );
}
